import { SURVEY_FETCH_FAILURE, SURVEY_FETCH_SUCCESS, FETCHING_SURVEY} from "../_actions/_types.actions";

const initialState = {
  survey_questions: [],
  id: 0,
  fetchingSurvey: false,
  surveyFetchError: null,
};

export default function survey(state = initialState, action) {
  switch (action.type) {
    case FETCHING_SURVEY:
      return Object.assign({}, state,{fetchingSurvey: action.status});
    case SURVEY_FETCH_SUCCESS:
      return Object.assign({}, state, {...action.data, surveyFetchError: null});
    case SURVEY_FETCH_FAILURE:
      return Object.assign({}, state, {surveyFetchError: action.error});
    default:
      return state;
  }
}

/*
Global Variables that are based off the state tree's structure
 */
const questionSort = (q1,q2) => (q1.display_order-q2.display_order);

export const getAllQuestions = (survey) => survey.survey_questions.map(q => {
  const {id, question, type, instructions, short_question, question_num, category, choices, display_options} = q.question;
  return {id, question, type, instructions, short_question, question_num, category, choices, display_options, display_order: q.display_order};
}).sort(questionSort);

// we only care about multiple choice questions that are marked to be shown on the charts'
// these questions are marked as such using question.display_options.show_on_chart
export const getMultipleChoiceQuestions = (survey) => {
  const questions = getAllQuestions(survey);
  return questions.filter(q => q.type === "MultipleChoiceQuestion" && (q.display_options && q.display_options.show_on_chart && q.display_options.show_on_chart === true));
};

export const getChoices = (survey, question) => {
  if (question && question.hasOwnProperty("choices")) {
    return question.choices
  } else return [];
};

export function getBurnoutQuestion(survey) {
  const questions = getAllQuestions(survey);
  return questions.find(q => q.short_question === process.env.REACT_APP_BURNOUT_SHORT_QUESTION);
}

export function getAgreeDisagreeQuestions(survey) {
  const questions = getMultipleChoiceQuestions(survey);
  return questions.filter(q => q.type === "MultipleChoiceQuestion"  && q.short_question !== process.env.REACT_APP_BURNOUT_SHORT_QUESTION);
}

export function getFreeTextQuestions(survey) {
  const questions = getAllQuestions(survey);
  return questions.filter(q => q.type === "FreeTextQuestion" && (q.display_options && q.display_options.show_on_chart && q.display_options.show_on_chart === true));
}

export function getHoursWorkedQuestion(survey) {
  const questions = getAllQuestions(survey);
  return questions.find(q => q.id === parseInt(process.env.REACT_APP_HOURS_WORKED_QUESTION));
}