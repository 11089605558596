import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import {loggedIn} from "./_redux/_reducers";


function RequireAuth({children, loggedIn}) {
  const location = useLocation();

  return loggedIn ? children : <Redirect to={{
    pathname: "/login",
    // no need for redirect_url query param if just going to root path after logging in
    search: location.pathname === '/' ?
      `${location.search}` :
      `?redirect_url=${location.pathname}${location.search.replace('?','&')}`,
    hash: location.hash,
    state: { from: location }
  }}/>
}

const mapStateToProps = state => (
{
  loggedIn: loggedIn(state)
});

export default connect(mapStateToProps)(RequireAuth);