import {
  SUBMITTING_FEEDBACK,
  SUBMIT_FEEDBACK_FAILURE,
  SUBMIT_FEEDBACK_SUCCESS,
  ADD_WATCHED_VIDEO,
  TOGGLE_FEEDBACK,
  RESET_WATCHED_VIDEOS
} from "../_actions/_types.actions";

const initialState = {
  submittingFeedback: false,
  feedbackError: null,
  watchedVideos: new Set([]),
  showFeedback: false,
};

export default function feedback(state = initialState, action) {
  switch (action.type) {
    case SUBMITTING_FEEDBACK:
      return Object.assign({}, state, {submittingFeedback: action.status});
    case SUBMIT_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {feedbackError: null});
    case SUBMIT_FEEDBACK_FAILURE:
      return Object.assign({}, state, {feedbackError: action.error});
    case ADD_WATCHED_VIDEO:
      const newWatchedVideos = new Set([
        ...state.watchedVideos,
        action.videoId
      ]);
      return Object.assign({}, state, {
        watchedVideos: newWatchedVideos
      });
    case TOGGLE_FEEDBACK:
      return Object.assign({},state,{showFeedback:action.status});
    case RESET_WATCHED_VIDEOS:
      return Object.assign({}, state, {
        watchedVideos: new Set([])
      });
    default:
      return state;
  }
}