// An Async Component used for dynamically loading components when they are needed (aka Code Splitting)
// This isn't really necessary due to the size of our app, but could come in handy down the road as the app grows.
// Also is a great thing to learn since it teaches you how components are loaded within a react app.
// For now we will just wrap the Login and Main Page in async components so that they are not imported until the users logs in or logs out.
// https://serverless-stack.com/chapters/code-splitting-in-create-react-app.html
import React, { Component } from "react";

export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);

      this.state = {
        component: null
      };
    }

    async componentDidMount() {
      const { default: component } = await importComponent();

      this.setState({
        component: component
      });
    }

    render() {
      const C = this.state.component;

      return C ? <C {...this.props} /> : null;
    }
  }

  return AsyncComponent;
}