/*
This index.js is the Root Reducer of Redux
 */
import { combineReducers } from 'redux';
import results, * as fromResults from './results_reducer';
import survey, * as fromSurvey from './survey_reducer';
import authentication, * as fromAuthentication from './authentication_reducer';
import comments_analysis, * as fromCommentsAnalysis from './comments_analysis_reducer';
import benchmarks, * as fromBenchmarks from './benchmarks_reducer';
import enrichment_center, * as fromEnrichmentCenter from './enrichment_center_reducer';
import burnout_blocks, * as fromBurnoutBlocks from './burnout_blocks_reducer';
import groups, * as fromGroups from './groups_reducer';
import feedback from './feedback_reducer';
import { LOGOUT } from "../_actions/_types.actions";

const appReducer = combineReducers({
  authentication,
  results,
  survey,
  benchmarks,
  enrichment_center,
  burnout_blocks,
  feedback,
  comments_analysis,
  groups
});
// why this "rootReducer" is declared here alongside "appReducer": https://alligator.io/redux/reset-state-redux/
const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    // RESET REDUX STATE ON LOGOUT
    localStorage.removeItem('currentBlock');
    localStorage.removeItem('currentSession');
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;

/*
-------------------------------
SELECTOR FUNCTIONS REDECLARED
why these functions are redeclared here:
https://egghead.io/lessons/javascript-redux-colocating-selectors-with-reducers
-------------------------------
*/

export const numResultsNeeded = fromResults.num_results_needed;

export const filterCommentsByAttribute = (state) =>
  fromCommentsAnalysis.filterCommentsByAttribute(state.comments_analysis, state.burnout_blocks?.currentBurnoutBlock?.id);

export const getPossibleCommentsFilters = (state) =>
  fromCommentsAnalysis.getPossibleCommentsFilters(state.comments_analysis, state.burnout_blocks?.currentBurnoutBlock?.id);

export const filterResults = (state, filterSet = -1, blockId = 0) =>
  fromResults.filterResults(state.results, filterSet, blockId);

export const getPossibleFilters = (state, blockId, filterSet = -1) =>
  fromResults.getPossibleFilters(state.results, blockId, filterSet);

export const getCommentsDemographics = (state) =>
  fromCommentsAnalysis.getCommentsDemographics(state.comments_analysis, state.burnout_blocks?.currentBurnoutBlock?.id);


export const getDemographics = (state, blockId) =>
  fromResults.getDemographics(state.results, blockId);

export const getCurrentFilters = (state, filterSet = 0) =>
  fromResults.getCurrentFilters(state.results, filterSet, state.burnout_blocks.currentBurnoutBlock.id);

export const getDataSetName = (state, filterSet = 0) =>
  fromResults.getDataSetName(state.results, filterSet, state.burnout_blocks.currentBurnoutBlock.id);

export const getHistoricalDataSetName = (state) =>
  fromResults.getHistoricalDataSetName(state.results, state.burnout_blocks);

export const getFrequencyData = (state, question, blockId, filterSet = -1) =>
  fromResults.getFrequencyData(state.results, question, blockId, filterSet);

export const getPresenceOfBurnoutRatio = (state, blockId, filterSet = 0) =>
  fromResults.getPresenceOfBurnoutRatio(state.results, blockId, filterSet);

export const getHistoricalPresenceOfBurnoutRatio = (state, burnoutBlocks, filterSet = 0) =>
  fromResults.getHistoricalPresenceOfBurnoutRatio(state.results, state.burnout_blocks, filterSet);

export const getAverageScores = (state, questions, blockId, filterSet = -1) =>
  fromResults.getAverageScores(state.results, questions, blockId, filterSet);

export const getNumFilteredResponses = (state, blockId, filterSet = -1) =>
  fromResults.getNumFilteredResponses(state.results, blockId, filterSet);

// Always grab for current block
export const getRespondentCharacteristics = (state) =>
  fromResults.getRespondentCharacteristics(state.results, state.burnout_blocks.currentBurnoutBlock.id);

// Always grab for current block
export const getRespPresenceOfBurnout = (state) =>
  fromResults.getRespPresenceOfBurnout(state.results, state.burnout_blocks.currentBurnoutBlock.id);

// Always grab for current block
export const getRespScores = (state, questions = getMultipleChoiceQuestions(state)) =>
  fromResults.getRespScores(state.results, questions, state.burnout_blocks.currentBurnoutBlock.id);

export const getCurrUserResponse = (state, question, blockId) =>
  fromResults.getCurrUserResponse(state.results, question, blockId);

export const defaultAgeMin = fromResults.defaultAgeMin;

export const defaultAgeMax = fromResults.defaultAgeMax;

export const ageRanges = fromResults.ageRanges;

export const ageRangeToString = fromResults.ageRangeToString;

export const getChoices = (state, question) =>
  fromSurvey.getChoices(state.survey, question);

export const getBurnoutQuestion = (state) =>
  fromSurvey.getBurnoutQuestion(state.survey);

export const getMultipleChoiceQuestions = (state) =>
  fromSurvey.getMultipleChoiceQuestions(state.survey);

export const getAllQuestions = (state) =>
  fromSurvey.getAllQuestions(state.survey);

export const getAgreeDisagreeQuestions = (state) =>
  fromSurvey.getAgreeDisagreeQuestions(state.survey);

export const getFreeTextQuestions = (state) =>
  fromSurvey.getFreeTextQuestions(state.survey);

export const getHoursWorkedQuestion = (state) =>
  fromSurvey.getHoursWorkedQuestion(state.survey);

export const getCurrentUser = (state) =>
  fromAuthentication.getCurrentUser(state.authentication)

export const loggedIn = (state) =>
  fromAuthentication.loggedIn(state.authentication);

export const isAdmin = (state) =>
  fromAuthentication.isAdmin(state.authentication);

export const isOrgLeader = (state) =>
  fromAuthentication.isOrgLeader(state.authentication);

export const canSeeLocations = (state) =>
  fromAuthentication.canSeeLocations(state.authentication);

export const canExcludeComments = (state) =>
  fromAuthentication.canExcludeComments(state.authentication);

export const canSeeCompletionStatistics = (state) =>
  fromAuthentication.canSeeCompletionStatistics(state.authentication);

export const canSeeResults = (state) =>
  fromAuthentication.canSeeResults(state.authentication, currentBlockStatus(state))

export const isMmodalUser = (state) =>
  fromAuthentication.isMmodalUser(state.authentication);

export const isAnaUser = (state) =>
  fromAuthentication.isAnaUser(state.authentication);

export const isAafpUser = (state) =>
  fromAuthentication.isAafpUser(state.authentication);

export const isPrismaUser = (state) =>
  fromAuthentication.isPrismaUser(state.authentication);

export const isCeraVeUser = (state) =>
  fromAuthentication.isCeraVeUser(state.authentication);

export const isDemoUser = (state) =>
  fromAuthentication.isDemoUser(state.authentication);

export const getLogoUrl = (state) => {
  let logoUrl;
  if (isAdmin(state)) {
    logoUrl = fromGroups.groupLogoUrl(state.groups);
  } else {
    logoUrl = fromAuthentication.getLogoUrl(state.authentication);
  }
  return logoUrl;
};

export const getGroupName = (state) => {
  let groupName;
  if (isAdmin(state)) {
    groupName = fromGroups.groupName(state.groups);
  } else {
    groupName = fromAuthentication.getGroupName(state.authentication);
  }
  return groupName;
};

export const getGroupId = (state) =>
  fromGroups.groupId(state.groups);

export const isTcmGroup = (state) =>
  fromGroups.isTcmGroup(state.groups);

export const currentBlockStatus = (state) =>
  fromBurnoutBlocks.currentBlockStatus(state.burnout_blocks);

export const blockIds = (state) =>
  fromBurnoutBlocks.blockIds(state.burnout_blocks);

export const getSpecialtyFilterName = (state) =>
  fromBurnoutBlocks.getSpecialtyFilterName(state.burnout_blocks);

export const getLicenseTypeFilterName = (state) =>
  fromBurnoutBlocks.getLicenseTypeFilterName(state.burnout_blocks);

export const getSectionByRecordId = (state, record_id) =>
  fromEnrichmentCenter.getSectionByRecordId(state.enrichment_center, record_id);

export const getSectionNameByRecordId = (state, record_id) =>
  fromEnrichmentCenter.getSectionNameByRecordId(state.enrichment_center, record_id);

export const contentByCategory = (state) =>
  fromEnrichmentCenter.contentByCategory(state.enrichment_center);

export const getContentBySection = (state, section) =>
  fromEnrichmentCenter.getContentBySection(state.enrichment_center, section);

export const getContentRecordId = (state, section, lesson_number) =>
  fromEnrichmentCenter.getContentRecordId(state.enrichment_center, section, lesson_number);

export const getContentAndDeeperWider = (state, record_id) =>
  fromEnrichmentCenter.getContentAndDeeperWider(state.enrichment_center, record_id);

export const getCmeCreditStatus = (state, lesson_number) =>
  fromEnrichmentCenter.getCmeCreditStatus(state.enrichment_center, lesson_number);

export const getContactHourStatus = (state, lesson_number) =>
  fromEnrichmentCenter.getContactHourStatus(state.enrichment_center, lesson_number);

export const getClaimedCmeCreditDates = (state, lesson_number) =>
  fromEnrichmentCenter.getClaimedCmeCreditDates(state.enrichment_center, lesson_number);

export const getClaimedContactHourDates = (state, lesson_number) =>
  fromEnrichmentCenter.getClaimedContactHourDates(state.enrichment_center, lesson_number);

export const getAverageScoresBenchmark = (state) =>
  fromBenchmarks.getAverageScoresBenchmark(state.benchmarks, state.results.filters[0].license);

export const getFrequencyScoresBenchmark = (state) =>
  fromBenchmarks.getFrequencyScoresBenchmark(state.benchmarks, state.results.filters[0].license);

export const getFrequencyOfBurnoutBenchmark = (state) =>
  fromBenchmarks.getFrequencyOfBurnoutBenchmark(state.benchmarks.frequency_scores, state.results.filters[0].license);

export const getKlasFrequencyOfBurnoutBenchmark = (state) =>
  fromBenchmarks.getFrequencyOfBurnoutBenchmark(state.benchmarks.klas_frequency_scores, state.results.filters[0].license);

export const getBurnoutRatioBenchmark = (state) =>
  fromBenchmarks.getBurnoutRatioBenchmark(getFrequencyOfBurnoutBenchmark(state), state.survey.survey_questions);

export const getKlasBurnoutRatioBenchmark = (state) =>
  fromBenchmarks.getBurnoutRatioBenchmark(getKlasFrequencyOfBurnoutBenchmark(state), state.survey.survey_questions);

export const getSectionName = (state, section) =>
  fromEnrichmentCenter.getSectionName(state.enrichment_center, section);
