// This component is used to pass the access and client token to redux after successful OAuth login

import React, {useEffect} from 'react';
import { connect } from 'react-redux';
import {get_user, update_access_token} from "./_redux/_actions/authentication.actions";
import {useLocation} from "react-router-dom";

const OauthRedirect = (props) => {
  const location = useLocation()
  const {update_access_token, get_user} = props;
  const params = new URLSearchParams(location.search);
  const id = params.get('id');
  const accessToken = params.get('token');
  const client = params.get('client');
  const email = params.get('email');
  const redirect_url = params.get('redirect_url');
  const expiry = params.get('expiry');
  useEffect(() => {
    update_access_token(accessToken,client,email,expiry)
    get_user({accessToken,client,email,expiry},id,redirect_url)
  })
  return <div></div>
}

export default connect(undefined, {update_access_token,get_user})(OauthRedirect);