import { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';

const RedirectComponent = () => {
  // const history = useHistory();

  useEffect(() => {
    window.location.href = "https://www.sehealthcaresolutions.com/";
  }, []);

  return null;
};

export default RedirectComponent;