import { applyMiddleware, createStore, compose } from 'redux';
import rootReducer from './_reducers';
import thunk from 'redux-thunk'
import logger from './redux_logger';
import monitor from './redux_monitor';

export default function configureStore(preloadedState = undefined) {
  const middleware = [thunk];
  if (process.env.NODE_ENV === 'development') {
    middleware.push(logger)
  }
  const middlewareEnhancer = applyMiddleware(...middleware);
  const enhancers = [middlewareEnhancer, monitor];

  let composeEnhancers = compose;

  if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  }

  // TODO change undefined to load "Initial State"
  // https://redux-docs.netlify.com/api/createstore
  const store = createStore(rootReducer, preloadedState, composeEnhancers(...enhancers));

  // Hot Reloading
  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  //   module.hot.accept('../../_reducers', () => store.replaceReducer(rootReducer))
  // }

  return store;
}