export function round(value, precision) {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
}

const frequency_scores = {
  "All": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "All Advanced Practice Providers": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "All Physicians": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "All Nurses": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Certified Nurse Midwife": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Certified Registered Nurse Anesthetist": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Certified Registered Nurse Practitioner": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Doctor of Optometry": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Doctor of Osteopathic Medicine": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Licensed Practical Nurse": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Medical Doctor": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Physical Therapist": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Physician": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Physician Assistant": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ],
  "Registered Nurse": [
    {
      name: "SE Healthcare Database",
      question_id: 1,
      short_question: "Burnout Level",
      data:[
        {name: "I enjoy my work. I have no symptoms of burnout.", y: round(18.10,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.", y: round(37.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.", y: round(28.88,process.env.REACT_APP_CHART_PRECISION)},
        {name: "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.", y: round(10.16,process.env.REACT_APP_CHART_PRECISION)},
        {name: "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.", y: round(5.19,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 2,
      short_question: "Professional/Personal Life Satisfaction",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 3,
      short_question: "Respect-Organization",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 4,
      short_question: "Values-Organization",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 5,
      short_question: "Trust-Leadership",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 6,
      short_question: "Resources Available",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 7,
      short_question: "Wellbeing-Organization",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 23,
      short_question: "Focused Attention",
      data:[
        {name: "Strongly Agree", y: round(26.39,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(34.72,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(27.08,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 24,
      short_question: "Provide Patient Quality",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 25,
      short_question: "Time Available for Patient Needs?",
      data:[
        {name: "Strongly Agree", y: round(41.67,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(39.58,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(6.94,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(2.78,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 26,
      short_question: "Increased Patient Volume",
      data:[
        {name: "Strongly Agree", y: round(43.07,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(37.50,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(9.03,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(9.02,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 27,
      short_question: "Clinical Staffing Levels",
      data:[
        {name: "Strongly Agree", y: round(44.44,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(29.17,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(10.42,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(4.86,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 28,
      short_question: "Clinical Staff-Sufficient Skills",
      data:[
        {name: "Strongly Agree", y: round(24.31,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(47.22,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(11.11,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(15.97,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(1.39,process.env.REACT_APP_CHART_PRECISION)}
      ]
    },
    {
      name: "SE Healthcare Database",
      question_id: 29,
      short_question: "Coaching and Training skills",
      data:[
        {name: "Strongly Agree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Agree", y: round(43.06,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Neither Agree nor Disagree", y: round(15.28,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Disagree", y: round(18.05,process.env.REACT_APP_CHART_PRECISION)},
        {name: "Strongly Disagree", y: round(5.56,process.env.REACT_APP_CHART_PRECISION)}
      ]
    }
  ]
};

const average_scores = {
  "All": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "All Advanced Practice Providers": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "All Physicians": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "All Nurses": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Certified Nurse Midwife": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Certified Registered Nurse Anesthetist": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Certified Registered Nurse Practitioner": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Doctor of Optometry": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Doctor of Osteopathic Medicine": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Licensed Practical Nurse": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Medical Doctor": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Physical Therapist": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Physician": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Physician Assistant": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  },
  "Registered Nurse": {
    name: "SE Healthcare Database",
    data: [
      {name: "I trust the senior leadership team of this organization.", short_question: "Trust-Leadership", y: round(74.48,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "In my organization, people treat each other with respect.", short_question: "Respect-Organization",y: round(77.08,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the current balance between my professional and personal life.", short_question: "Professional/Personal Life Satisfaction", y: round(62.50,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My values are aligned with the values of this organization.", short_question: "Values-Organization",y: round(77.95,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My organization prioritizes provider well-being.",short_question: "Wellbeing-Organization",y: round(68.23,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have all the resources I need to do my job right.",short_question: "Resources Available",y: round(69.27,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am satisfied with the number of clinical support staff.",short_question: "Clinical Staffing Levels",y: round(70.21,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "My clinical support staff have sufficient skills to relieve most of the administrative burden associated with patient visits.",short_question: "Clinical Staff-Sufficient Skills",y: round(69.01,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have been seeing more patients per day than I was at this time last year.",short_question: "Increased Patient Volume",y: round(69.10,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I have room in my schedule to see my patients the same day when an urgent issue arises and they need to be seen.",short_question: "Time Available for Patient Needs?",y: round(74.78,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to deliver the highest quality of care to my patients.",short_question: "Provide Patient Quality",y: round(71.52,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "I am able to provide focused attention to my patients.",short_question: "Focused Attention",y: round(75.75,process.env.REACT_APP_CHART_PRECISION),response_count: 768},
      {name: "As a team leader, I feel well-equipped to train and coach my clinical support staff on an on-going basis.",short_question: "Coaching and Training skills",y: round(67.30,process.env.REACT_APP_CHART_PRECISION),response_count: 768}
    ]
  }
};

const klas_frequency_scores = {
  "All Physicians": [
    {
      "name": "KLAS Arch Collaborative Physician",
      "question_id": 1,
      "short_question": "Burnout Level",
      "data": [
        {
          "name": "I enjoy my work. I have no symptoms of burnout.",
          "y": 24.81,
          "response_count": 3246,
          "score": 100
        },
        {
          "name": "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.",
          "y": 43.16,
          "response_count": 5648,
          "score": 75
        },
        {
          "name": "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.",
          "y": 22.75,
          "response_count": 2977,
          "score": 50
        },
        {
          "name": "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.",
          "y": 8.06,
          "response_count": 1055,
          "score": 25
        },
        {
          "name": "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.",
          "y": 1.22,
          "response_count": 160,
          "score": 0
        }
      ]
    }
  ],
  "All Nurses": [
    {
      "name": "KLAS Arch Collaborative Nurse",
      "question_id": 1,
      "short_question": "Burnout Level",
      "data": [
        {
          "name": "I enjoy my work. I have no symptoms of burnout.",
          "y": 28.35,
          "response_count": 5299,
          "score": 100
        },
        {
          "name": "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.",
          "y": 39.91,
          "response_count": 7461,
          "score": 75
        },
        {
          "name": "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.",
          "y": 23.45,
          "response_count": 4383,
          "score": 50
        },
        {
          "name": "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.",
          "y": 7.08,
          "response_count": 1324,
          "score": 25
        },
        {
          "name": "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.",
          "y": 1.21,
          "response_count": 227,
          "score": 0
        }
      ]
    }
  ],
  "All Advanced Practice Providers": [
    {
      "name": "KLAS Arch Collaborative APP",
      "question_id": 1,
      "short_question": "Burnout Level",
      "data": [
        {
          "name": "I enjoy my work. I have no symptoms of burnout.",
          "y": 26.49,
          "response_count": 3671,
          "score": 100
        },
        {
          "name": "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.",
          "y": 42.47,
          "response_count": 5885,
          "score": 75
        },
        {
          "name": "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.",
          "y": 22.62,
          "response_count": 3134,
          "score": 50
        },
        {
          "name": "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.",
          "y": 6.9,
          "response_count": 956,
          "score": 25
        },
        {
          "name": "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.",
          "y": 1.52,
          "response_count": 211,
          "score": 0
        }
      ]
    }
  ],
  "All": [
    {
      "name": "KLAS Arch Collaborative",
      "question_id": 1,
      "short_question": "Burnout Level",
      "data": [
        {
          "name": "I enjoy my work. I have no symptoms of burnout.",
          "y": 27.29,
          "response_count": 13280,
          "score": 100
        },
        {
          "name": "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.",
          "y": 41.5,
          "response_count": 20192,
          "score": 75
        },
        {
          "name": "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.",
          "y": 22.7,
          "response_count": 11048,
          "score": 50
        },
        {
          "name": "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.",
          "y": 7.19,
          "response_count": 3499,
          "score": 25
        },
        {
          "name": "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.",
          "y": 1.32,
          "response_count": 642,
          "score": 0
        }
      ]
    }
  ],
  "Advanced Practice Provider": [
    {
      "name": "KLAS Arch Collaborative APP",
      "question_id": 1,
      "short_question": "Burnout Level",
      "data": [
        {
          "name": "I enjoy my work. I have no symptoms of burnout.",
          "y": 26.49,
          "response_count": 3671,
          "score": 100
        },
        {
          "name": "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.",
          "y": 42.47,
          "response_count": 5885,
          "score": 75
        },
        {
          "name": "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.",
          "y": 22.62,
          "response_count": 3134,
          "score": 50
        },
        {
          "name": "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.",
          "y": 6.9,
          "response_count": 956,
          "score": 25
        },
        {
          "name": "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.",
          "y": 1.52,
          "response_count": 211,
          "score": 0
        }
      ]
    }
  ],
  "Nurse": [
    {
      "name": "KLAS Arch Collaborative Nurse",
      "question_id": 1,
      "short_question": "Burnout Level",
      "data": [
        {
          "name": "I enjoy my work. I have no symptoms of burnout.",
          "y": 28.35,
          "response_count": 5299,
          "score": 100
        },
        {
          "name": "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.",
          "y": 39.91,
          "response_count": 7461,
          "score": 75
        },
        {
          "name": "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.",
          "y": 23.45,
          "response_count": 4383,
          "score": 50
        },
        {
          "name": "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.",
          "y": 7.08,
          "response_count": 1324,
          "score": 25
        },
        {
          "name": "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.",
          "y": 1.21,
          "response_count": 227,
          "score": 0
        }
      ]
    }
  ],
  "Other": [
    {
      "name": "KLAS Arch Collaborative Other",
      "question_id": 1,
      "short_question": "Burnout Level",
      "data": [
        {
          "name": "I enjoy my work. I have no symptoms of burnout.",
          "y": 35.19,
          "response_count": 1064,
          "score": 100
        },
        {
          "name": "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.",
          "y": 39.62,
          "response_count": 1198,
          "score": 75
        },
        {
          "name": "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.",
          "y": 18.32,
          "response_count": 554,
          "score": 50
        },
        {
          "name": "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.",
          "y": 5.42,
          "response_count": 164,
          "score": 25
        },
        {
          "name": "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.",
          "y": 1.46,
          "response_count": 44,
          "score": 0
        }
      ]
    }
  ],
  "Physician": [
    {
      "name": "KLAS Arch Collaborative Physician",
      "question_id": 1,
      "short_question": "Burnout Level",
      "data": [
        {
          "name": "I enjoy my work. I have no symptoms of burnout.",
          "y": 24.81,
          "response_count": 3246,
          "score": 100
        },
        {
          "name": "Occasionally I am under stress and I don't have as much energy as I once did, but I don't feel burned out.",
          "y": 43.16,
          "response_count": 5648,
          "score": 75
        },
        {
          "name": "I am definitely burning out and have one or more symptoms of burnout, such as physical and emotional exhaustion.",
          "y": 22.75,
          "response_count": 2977,
          "score": 50
        },
        {
          "name": "The symptoms of burnout that I'm experiencing won't go away. I think about frustration at work a lot.",
          "y": 8.06,
          "response_count": 1055,
          "score": 25
        },
        {
          "name": "I feel completely burned out and often wonder if I can go on. I am at the point where I may need some changes or may need to seek some sort of help.",
          "y": 1.22,
          "response_count": 160,
          "score": 0
        }
      ]
    }
  ]
}

const benchmarks = {
  average_scores,
  frequency_scores,
  klas_frequency_scores
};

export default benchmarks;