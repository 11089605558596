import {
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_PENDING,
  DISMISS_SUCCESS,
  DISMISS_ERROR,
  LOGOUT,
  CHECKING_CONFIRMATION_TOKEN,
  CONFIRMATION_TOKEN_ERROR,
  CONFIRMATION_TOKEN_VALID,
  SETTING_PASSWORD,
  PASSWORD_SET_SUCCESS,
  PASSWORD_SET_FAILURE,
  REQUESTING_PASSWORD_RESET,
  REQUEST_PASSWORD_RESET_SUCCESS,
  REQUEST_PASSWORD_RESET_FAILURE,
  RESETTING_PASSWORD,
  PASSWORD_RESET_SUCCESS,
  PASSWORD_RESET_FAILURE,
  VALIDATING_TOKEN,
  TOKEN_VALIDATION_FAILURE,
  TOKEN_VALIDATION_SUCCESS,
  CREATING_USER,
  USER_CREATION_SUCCESS,
  USER_CREATION_FAILURE,
  GETTING_USER,
  USER_GET_SUCCESS,
  USER_GET_FAILURE,
  UPDATING_USER,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_FAILURE,
  AUTHENTICATION_ERROR,
  UPDATING_SHOW_MOBILE_MODAL,
  UPDATE_ACCESS_TOKEN,
  SUCCESS_MESSAGE,
  RESENDING_SURVEY,
  RESENT_SURVEY_SUCCESS,
  RESENT_SURVEY_FAILURE,
  SET_SHOW_PULSE_CHECK_SURVEY
} from "../_actions/_types.actions";
// LOGOUT is handled in the root_reducer so that it can clean out all of redux
// TODO need to not pull all of user data from localStorage since this can be manipulated client side
let existingSession = JSON.parse(localStorage.getItem('currentSession'));
const initialState = existingSession ? Object.assign(existingSession,
    {
      requestPending: false,
      requestError: null,
      successMessage: null,
      showMobileModal: true,
    }) :
  {
    requestPending: false,
    requestError: null,
    successMessage: null,
    user: {},
    showMobileModal: true,
    accessToken: null,
    client: null,
    email: null,
    expiry: null,
    showPulseCheckSurvey: false
  };

export default function authentication(state = initialState, action) {
  switch(action.type) {
    case LOGIN_PENDING:
      return Object.assign({}, state,{
        requestPending: action.status,
        requestError: action.status ? null : state.requestError
      });
    case LOGIN_FAILURE:
      return Object.assign({}, state,{
        requestError: action.error,
        successMessage: null
      });
    case LOGIN_SUCCESS:
      return Object.assign({}, state,{
        requestError: null,
        user: action.response.data.data,
        email: action.response.headers.uid,
        accessToken: action.response.headers["access-token"],
        client: action.response.headers.client,
        expiry: action.response.headers.expiry
      });
    case DISMISS_ERROR:
      return Object.assign({}, state,{
        requestError: null
      });
    case DISMISS_SUCCESS:
      return Object.assign({}, state,{
        successMessage: null
      });
    case LOGOUT:
      return Object.assign({}, state,{
        requestError: action.error,
        user: {},
        successMessage: null
      });
    case CHECKING_CONFIRMATION_TOKEN:
      return Object.assign({}, state,{
        requestError: action.status ? null : state.requestError
      });
    case CONFIRMATION_TOKEN_ERROR:
      return Object.assign({}, state,{
        requestError: action.error,
        successMessage: null
      });
    case CONFIRMATION_TOKEN_VALID:
      return Object.assign({}, state,{
        confirmationValid: action.status,
        requestError: null,
        email: action.status.data.email,
        user_id: action.status.data.id
      });
    case SETTING_PASSWORD:
      return Object.assign({}, state, {
        requestPending: action.status,
        requestError: action.status ? null : state.requestError
      });
    case PASSWORD_SET_SUCCESS:
      return Object.assign({}, state, {
        successMessage: action.status,
        requestError: null
      });
    case PASSWORD_SET_FAILURE:
      return Object.assign({}, state, {
        requestError: action.error,
        successMessage: null
      });
    case REQUESTING_PASSWORD_RESET:
      return Object.assign({}, state, {
        requestPending: action.status,
        requestError: action.status ? null : state.requestError
      });
    case REQUEST_PASSWORD_RESET_SUCCESS:
      return Object.assign({}, state, {
        successMessage: action.status,
        requestError: null
      });
    case REQUEST_PASSWORD_RESET_FAILURE:
      return Object.assign({}, state, {
        requestError: action.error,
        successMessage: null
      });
    case RESETTING_PASSWORD:
      return Object.assign({}, state,{
        requestPending: action.status,
        requestError: action.status ? null : state.requestError
      });
    case PASSWORD_RESET_SUCCESS:
      return Object.assign({}, state, {
        successMessage: action.status,
        requestError: null
      });
    case PASSWORD_RESET_FAILURE:
      return Object.assign({}, state, {
        requestError: action.error,
        successMessage: null
      });
    case VALIDATING_TOKEN:
      return Object.assign({}, state, {
        validatingToken: action.status
      });
    case TOKEN_VALIDATION_SUCCESS:
      return Object.assign({}, state, {
        requestError: null
      });
    case TOKEN_VALIDATION_FAILURE:
      return Object.assign({}, state, {
        requestError: action.error,
        successMessage: null
      });
    case CREATING_USER:
      return Object.assign({}, state,{
        requestPending: action.status,
        requestError: action.status ? null : state.requestError
      });
    case USER_CREATION_SUCCESS:
      return Object.assign({}, state, {
        successMessage: action.status,
        requestError: null
      });
    case USER_CREATION_FAILURE:
      return Object.assign({}, state, {
        requestError: action.error,
        successMessage: null
      });
    case GETTING_USER:
      return Object.assign({}, state, {
        requestPending: action.status,
        requestError: action.status ? null : state.requestError
      });
    case USER_GET_SUCCESS:
      return Object.assign({}, state,{
        requestError: null,
        user: action.response.data
      });
    case USER_GET_FAILURE:
      return Object.assign({}, state, {
        requestError: action.error,
        successMessage: null
      });
    case UPDATING_USER:
      return Object.assign({}, state, {
        requestPending: action.status,
        requestError: action.status ? null : state.requestError
      });
    case USER_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        user: action.response.data
      });
    case USER_UPDATE_FAILURE:
      return Object.assign({}, state, {
        requestError: action.error,
        successMessage: null
      });
    case AUTHENTICATION_ERROR:
      return Object.assign({}, state, {
        requestError: action.error,
        successMessage: null
      });
    case SUCCESS_MESSAGE:
      return Object.assign({}, state, {
        successMessage: action.status,
        requestError: null
      })
    case UPDATE_ACCESS_TOKEN:
      return (Object.assign(
        {},
        state,
        {
          accessToken: action.accessToken,
          client: action.client,
          email: action.email,
          expiry: action.expiry
        })
      )
    case UPDATING_SHOW_MOBILE_MODAL:
      return (Object.assign({}, state, { showMobileModal: action.bool }))
    case RESENDING_SURVEY:
      return Object.assign({}, state, {
        requestPending: action.status,
        requestError: action.status ? null : state.requestError
      });
    case RESENT_SURVEY_SUCCESS:
      return Object.assign({}, state, {
        successMessage: action.status,
        requestError: null
      });
    case RESENT_SURVEY_FAILURE:
      return Object.assign({}, state, {
        requestError: action.error,
        successMessage: null
      });
    case SET_SHOW_PULSE_CHECK_SURVEY:
      return Object.assign({}, state, { showPulseCheckSurvey: action.value });
    default:
      return state;
  }
}

export const getCurrentUser = authentication => authentication.user

export const loggedIn = authentication => (
  authentication.user && Object.getOwnPropertyNames(authentication.user).length > 0
);

export const isAdmin = authentication => (
  authentication.user && authentication.user.burnout_role && authentication.user.burnout_role.includes('se_admin')
);

export const isOrgLeader = authentication => (
  authentication.user && authentication.user.burnout_role && authentication.user.burnout_role.includes('org_leader')
);

export const isMmodalUser = authentication => (
  authentication.user && authentication.user.practice_group_ids && authentication.user.practice_group_ids.includes(parseInt(process.env.REACT_APP_MMODAL_GROUP_ID))
);

export const isAnaUser = authentication => (
  authentication.user && authentication.user.practice_group_ids && authentication.user.practice_group_ids.includes(parseInt(process.env.REACT_APP_ANA_GROUP_ID))
);

export const isAafpUser = authentication => (
  authentication.user && authentication.user.practice_group_ids && authentication.user.practice_group_ids.includes(parseInt(process.env.REACT_APP_AAFP_GROUP_ID))
);

export const isPrismaUser = authentication => (
  authentication.user && authentication.user.practice_group_ids && authentication.user.practice_group_ids.includes(parseInt(process.env.REACT_APP_PRISMA_GROUP_ID))
);

export const isCeraVeUser = authentication => (
  authentication.user && authentication.user.practice_group_ids && authentication.user.practice_group_ids.includes(parseInt(process.env.REACT_APP_CERAVE_GROUP_ID))
);

export const isDemoUser = authentication => (
  authentication.user && authentication.user.email && authentication.user.email.substring(0,5) === 'demo_'
);

// This is a temp fix for MPL-like groups since locations shouldn't be able to see other locations
export const canSeeLocations = authentication => (
  (authentication.user && (authentication.user.burnout_role && (
    authentication.user.burnout_role === 'se_admin' ||
    authentication.user.burnout_role === 'system_admin' ||
    authentication.user.burnout_role === 'org_leader'
  ))) || getGroupName(authentication) !== 'CPP'
);

export const canExcludeComments = authentication => (
  authentication.user && authentication.user.burnout_role && (
    authentication.user.burnout_role === 'se_admin' ||
    authentication.user.burnout_role === 'system_admin' ||
    authentication.user.burnout_role === 'org_leader'
  )
);

export const canSeeCompletionStatistics = authentication => (
  authentication.user && authentication.user.burnout_role && (
    authentication.user.burnout_role === 'se_admin' ||
    authentication.user.burnout_role === 'system_admin' ||
    authentication.user.burnout_role === 'org_leader'
    // || authentication.user.burnout_role === 'physician_leader'
  )
);

export const canSeeResults = (authentication, blockStatus) => (
  blockStatus &&
  blockStatus !== 'scheduled' &&
  authentication.user && authentication.user.burnout_role && (
    // allow se_admins to see
    authentication.user.burnout_role === 'se_admin' || (
      // allow org leaders to see if in_review
      authentication.user.burnout_role === 'org_leader' && blockStatus === 'in_review'
    //   allow everyone to see if finished
    ) || blockStatus === 'finished'
  )
)

// only checking for practice_group logo
export const getLogoUrl = authentication => {
  let logoUrl = null;
  if(authentication.user) {
    if(authentication.user.practice_group) {
      logoUrl = authentication.user.practice_group.logo_url
    } else if(authentication.user.practice_groups && authentication.user.practice_groups.length > 0) {
      logoUrl = authentication.user.practice_groups[0].logo_url
    }
  }

  return logoUrl
};

export const getGroupName = authentication => {
  let groupName = null;
  if(authentication.user) {
    if(authentication.user.practice_group) {
      groupName = authentication.user.practice_group.name
    } else if(authentication.user.practice_groups && authentication.user.practice_groups.length > 0) {
      groupName = authentication.user.practice_groups[0].name
    }
  }

  return groupName
};