import React from "react";
import styles from "./LoginPage/login_page.module.scss";
import {Link} from "react-router-dom";

const TermsAndConditions = props => {
  return (
    <div style={{margin: "2rem"}}>
      <Link to={
        Object.assign({},props.location,{pathname: "/login"})
      } className={styles.backToLogin}>
        {"← Back to Login"}
      </Link>
      <h1 style={{textAlign: "center"}}>TERMS & CONDITIONS</h1>
      <p>
        In connection with your use of SE Healthcare Quality Consulting, LLC’s (“SE”) proprietary software and online subscriptions to its service platform (the “System”), you hereby agree to the following:
        <ol>
          <li>
            System License. Subject to the terms of this Agreement, SE hereby grants to you a limited, non-exclusive, and non-transferable license (the “license”) to use the System until terminated by SE. The license allows authorized users (each a “User” and collectively, “Users”) to have remote access to the System via a web site (the “System Website”). SE may utilize a third-party web host and other third party providers in order to make available the System and deliver the services described in this license (each a “System Provider”). Authorized users are healthcare providers or administrators whose name and email addresses have been provided to SE in connection with a license of the System and have been supplied user identifications and passwords by SE.
          </li>
          <li>
            Restrictions. You agree that you will not:
            <ol type="a">
              <li>
                reverse assemble, reverse engineer, decompile, translate, or otherwise attempt to derive the source code or related tools or technology from the System or any module or component thereof, nor copy any of the foregoing;
              </li>
              <li>
                copy, reproduce, modify, sell, lease, sublicense, market or commercially exploit in any way the System or any module or component thereof;
              </li>
              <li>
                disclose or grant access to a User name, the System or any module or component thereof to anyone who is not an authorized user; or
              </li>
              <li>
                take any other action with respect to the System that is otherwise prohibited by SE or any System Provider.
              </li>
            </ol>
          </li>
          <li>
            Ownership and Use of System; License to Data; Ownership of Feedback, Etc.
            <ol type={"a"}>
              <li>
                SE and each System Provider (as applicable) retains all right, title and interest and all intellectual property rights (including copyrights, designs, business methods, System Technology (as defined below), trade secrets, System Brands (as defined below) and other trademarks and patent rights) in and to the System, all related documentation and the System Website (collectively, the “System Materials”) and all copies thereof. Except as expressly stated in this Agreement, you have no ownership of or license to any System Materials or to any copies. You may not remove any proprietary rights legends from any System Materials. “System Brands” means any designs, colors, logos, copyrights, trademarks and service marks, whether registered or unregistered, of SE or any System Provider. “System Technology” means the network and content deployment technology of SE or any System Provider, including all improvements, revisions and additions developed by or for SE or any System Provider for use in the System, and all other technology owned or licensed by SE or any System Provider.
              </li>
              <li>
                You represent and warrant that you have the right to use the data of or relating to you and your patients, and have the right to grant SE a license to use all such data as described in this Agreement. You hereby grant to SE a nonexclusive, irrevocable, perpetual, royalty free, right and license to use (with the right to use aggregated, de-identified data) all the data of or relating to you and your patients, including all System reports and survey results. You further grant SE a license to include your trademarks and logos on your dedicated pages at the System Website and other communications in connection with the System.
              </li>
              <li>
                SE shall exclusively own any suggestions, feedback, recommendations, or other similar information provided by you to SE relating to the System or the System Materials.
              </li>
            </ol>
          </li>
          <li>
            User Responsibilities.
            <ol type={"a"}>
              <li>
                You shall implement commercially reasonable security systems, hardware, software, and procedures necessary to maintain the security and integrity of your information systems and data.. You shall notify SE immediately if there is a security breach or unauthorized use of the System or any user name or password. You shall permit SE to visit your premises on reasonable notice to review and audit your use of the System.
              </li>
              <li>
                You will only use the System for lawful purposes and will establish policies and procedures (including monitoring mechanisms) to guard against improper use of the System by your Users.
              </li>
              <li>
                You acknowledge and agree that a key component of the System involves measurement of clinical effectiveness and physician wellness and validation of your responses. As such, you agree to provide, within 10 business days of request by SE, certain medical records specified by SE to validate responses inputted to the clinical effectiveness module of the System.
              </li>
            </ol>
          </li>
          <li>
            Confidential Information. Each party acknowledges that confidential information (including trade secrets and confidential technical, financial and business information) (collectively, “Confidential Information”) may be exchanged between them in connection with the transactions contemplated by this Agreement. Each party shall use commercially reasonable efforts to prevent the disclosure and to protect the confidentiality of the Confidential Information of the other party. Each party agrees that it will not disclose or use the Confidential Information of the other party except to further the purposes of this Agreement and as authorized herein and in accordance with Section 9 below.
          </li>
          <li>
            Limited Warranty.
            <ol type={"a"}>
              <li>
                SE represents and warrants to you that (i) SE has all rights necessary to provide a Subscription to use the System to you pursuant to this Agreement; (ii) SE will use commercially reasonable efforts to make the System available for use by you; and (iii) the System will perform substantially in accordance with its associated documentation provided to you.
              </li>
              <li>
                EXCEPT AS PROVIDED IN SECTION 6(a), THE SYSTEM, THE SYSTEM WEBSITE, AND ALL SERVICES AND DATA ACCESSED OR RECEIVED BY YOU THROUGH THE SYSTEM AND THE SYSTEM WEBSITE ARE PROVIDED ON AN “AS IS”, “AS AVAILABLE” BASIS AND FOR EDUCATIONAL PURPOSES ONLY. SE AND THE SYSTEM PROVIDERS EXPRESSLY DISCLAIM ALL OTHER WARRANTIES, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE. Specifically, and notwithstanding any other term, condition or provision of this agreement, SE and the System Providers do not warrant that the System or System Website will increase sales, margins, profits, or efficiency; will reduce costs or expenses; will improve reimbursement rates or medical professional liability losses or risk experience; will decrease insurance premiums, or will improve operations or competitiveness or have any other particular result or effect.
              </li>
              <li>
                IN THE EVENT THAT THE SYSTEM OR SERVICES PROVIDED BY SE HEREUNDER FAIL TO CONFORM TO THE FOREGOING WARRANTY, SE'S SOLE OBLIGATION, AND YOUR SOLE REMEDY, IN ADDITION TO SE’S OBLIGATION TO INDEMNIFY YOU AS PROVIDED IN SECTION 8(b) BELOW, SHALL BE FOR SE TO USE COMMERCIALLY REASONABLE EFFORTS TO CORRECT SUCH NONCONFORMITY.
              </li>
            </ol>
          </li>
          <li>
            Limitation of Liability.
            <ol>
              <li>
                IN NO EVENT SHALL SE OR ANY SYSTEM PROVIDER BE LIABLE TO YOU FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR CONSEQUENTIAL DAMAGES RESULTING FROM A DEFAULT OF THIS AGREEMENT, OR YOUR OR ANY USER’S USE OF OR INABILITY TO USE THE SYSTEM OR THE SYSTEM WEBSITE, OR ANY DATA ACCESSED OR RECEIVED BY YOU OR ANY USER FROM THE SYSTEM OR THE SYSTEM WEBSITE, INCLUDING, WITHOUT LIMITATION, LOST BUSINESS, LOST SAVINGS OR LOST PROFITS EVEN IF SE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT SHALL SE BE LIABLE TO YOU FOR ANY DAMAGES ARISING FROM THE ACTS OR OMISSIONS OF THIRD PARTIES (INCLUDING ANY SYSTEM PROVIDER(S)) OR FOR THE SERVICES THAT MAY BE PROVIDED BY SUCH THIRD PARTIES THROUGH THE SYSTEM OR THE SYSTEM WEBSITE.
              </li>
              <li>
                THE LIABILITY OF SE TO YOU FOR DIRECT DAMAGES FOR ANY CLAIM IN CONNECTION WITH THIS AGREEMENT, OR YOUR USE OR ACCESS TO THE SYSTEM OR THE SYSTEM WEBSITE, OR ANY DATA ACCESSED OR RECEIVED THEREFROM SHALL NOT EXCEED THE TOTAL FEES PAID BY YOU TO SE UNDER THIS AGREEMENT IN THE 12 MONTH PERIOD IMMEDIATELY PRECEDING SUCH CLAIM.
              </li>
              <li>
                NEITHER SE NOR ANY SYSTEM PROVIDER SHALL HAVE ANY RESPONSIBILITY FOR ANY SECURITY BREACH, HACKING, INTRUSION OR OTHER UNAUTHORIZED ACCESS TO OR INVOLVING YOUR SYSTEMS, USER NAMES OR PASSWORDS.
              </li>
              <li>
                In those states that do not allow for any of the foregoing exclusions of liability, SE’s and any System Provider’s liability is limited to the fullest extent permitted by law.
              </li>
              <li>
                These limitations do not preclude SE’s responsibility for any fines, penalties, or damages that may be assessed or awarded against it arising out of SE’s breach of patient confidentiality under state or federal law.
              </li>
              <li>
                External links in the System provide additional information that is consistent with SE Healthcare’s mission. Linking to a non-SE Healthcare site does not constitute an endorsement by SE Healthcare or any of its employees of the sponsors or the information and products presented on the site. SE Healthcare cannot attest to the accuracy of information provided by these links. You will be subject to the destination site's privacy policy when you follow the link.
                As the content of these websites is not under our control, we cannot assume any liability for such external content. In all cases, the provider of information of the linked websites is liable for the content and accuracy of the information provided.
              </li>
            </ol>
          </li>
          <li>
            Indemnification.
            <ol type={"a"}>
              <li>
                You agree to defend, indemnify and hold SE, any System Provider, and their respective affiliates, employees, officers, directors and shareholders and their successors and assigns harmless from and against any claims, suits, actions or proceedings brought and damages, costs (including attorney’s fees) or judgments awarded (collectively, “Losses”) that arise from or in connection with (i) claims by any person or entity to the extent that such Losses are based upon or arise out of your use of the System or your actions or omissions; (ii) breach by you of this Agreement; or (iii) your failure to comply with any applicable law.
              </li>
              <li>
                Subject to the terms of Section 7, SE agrees to defend, indemnify and hold you harmless from and against any Losses to the extent based upon a third party’s claim that the System infringes any U.S. copyright, patent or trademark of a third party (an “Infringement Claim”). If any Infringement Claim is brought or threatened, SE shall, at its sole option and expense, (i) procure the right to continue to provide the System or the allegedly infringing part thereof; or (ii) modify or amend the System or the allegedly infringing party thereof in such a way as make it non-infringing, or replace it with other items having substantially the same capabilities. If neither option (i) or (ii) is commercially practicable in SE’s reasonable judgment, SE may terminate this Agreement with respect to any infringing aspect and refund any prepaid Fees relating thereto on a pro rata basis. SE shall have no liability to you or any third party for any Infringement Claim based upon your use of the System in combination with any computer hardware or system or services not specified or approved in writing by SE, or use of the System contrary.to the specifications or directions contained in the System documentation provided by SE or the applicable Systems Provider. The remedies set forth in this Section 8(b) shall be your sole and exclusive remedy in the event of an Infringement Claim.
              </li>
            </ol>
          </li>
          <li>
            Responsibilities Regarding HIPAA and PHI.
            <ol type={"a"}>
              <li>
                Applicability and Definitions. “HIPAA” means the Administrative Simplification Subtitle of the Health Insurance Portability and Accountability Act of 1996, as amended by Subtitle D of the 2009 Health Information Technology for Economic and Clinical Health (HITECH) Act, and their implementing regulations. “PHI” means “protected health information” as defined in 45 C.F.R. § 160.103 (the “Definitions”) that is received by SE from or on behalf of a “covered entity”, as such term is defined in the Definitions (the “Covered Entity”). All other capitalized terms used in this Section 9 but not otherwise defined herein, shall have the meanings ascribed to them in the Definitions.
              </li>
              <li>
                SE’s Obligations.
                <ol type={"i"}>
                  <li>
                    Limit on Uses and Disclosures. SE will use or disclose PHI only as permitted herein or as required by law, provided that any such use or disclosure would not violate HIPAA if done by a covered entity, unless permitted under HIPAA for a business associate.
                  </li>
                  <li>
                    Safeguards. SE will use reasonable and appropriate safeguards to prevent Use or Disclosure of the PHI other than as provided for herein, consistent with the requirements of Subpart C of 45 C.F.R. Part 164 (with respect to Electronic PHI) as determined by SE and as reflected herein.
                  </li>
                  <li>
                    Location. SE will not store or transmit PHI outside of the U.S.
                  </li>
                </ol>
              </li>
              <li>
                Reporting.
                <ol type={"i"}>
                  <li>
                    Reporting of Impermissible Uses and Disclosures. SE will report to the Covered Entity any Use or Disclosure of PHI not permitted or required by this BAA of which SE becomes aware.
                  </li>
                  <li>
                    Reporting of Security Incidents. SE will report to the Covered Entity on no less than a quarterly basis any Security Incidents involving PHI of which SE becomes aware in which there is a successful unauthorized access, use, disclosure, modification, or destruction of information or interference with any system operations in a manner that risks the confidentiality, integrity, or availability of such information. Notice is hereby deemed provided, and no further notice will be provided, for unsuccessful attempts at such unauthorized access, use, disclosure, modification, or destruction, such as pings and other broadcast attacks on a firewall, denial of service attacks, port scans, unsuccessful login attempts, or interception of encrypted information where the key is not compromised, or any combination of the above.
                  </li>
                  <li>
                    Reporting of Breaches. SE will report to the Covered Entity any Breach of the Covered Entity’s Unsecured PHI that SE may discover to the extent required by 45 C.F.R. § 164.410. SE will make such report without unreasonable delay.
                  </li>
                </ol>
              </li>
              <li>
                Subcontractors. SE will ensure that any subcontractors that create, receive, maintain, or transmit PHI on behalf of SE agree to restrictions and conditions at least as stringent as those found herein, and agree to implement reasonable and appropriate safeguards to protect PHI.
              </li>
              <li>
                Access to PHI. SE will make PHI in a Designated Record Set available to the Covered Entity so that the Covered Entity can comply with 45 C.F.R. § 164.524.
              </li>
              <li>
                Amendment to PHI. SE will make PHI in a Designated Record Set available to the Covered Entity for amendment and incorporate any amendments to the PHI, as may reasonably be requested by the Covered Entity in accordance with 45 C.F.R. § 164.526.
              </li>
              <li>
                Accounting of Disclosures. SE will make available to the Covered Entity the information required to provide an Accounting of Disclosures in accordance with 45 C.F.R. § 164.528 of which SE is aware, if requested by the Covered Entity.
              </li>
              <li>
                Internal Records. SE will make its internal practices, books, and records relating to the Use and Disclosure of PHI available to the Secretary of the U.S. Department of Health and Human Services (“HHS”) for purposes of determining the Covered Entity’s compliance with HIPAA. Nothing in this section will waive any applicable privilege or protection, including with respect to trade secrets and confidential commercial information.
              </li>
              <li>
                Covered Entity Obligations.
                <ol type={"i"}>
                  <li>
                    Notification of Changes to Notice of Privacy Practices. Covered Entity shall notify SE, in writing and in a timely manner, of any changes in, or limitations(s) in the Notice of Privacy Practices that Covered Entity provides to individuals pursuant to the HIPAA Regulations, to the extent such limitation or change may affect SE’s use or disclosure of PHI.
                  </li>
                  <li>
                    Individual Revocation of Authorization. Covered Entity shall inform SE, in writing and in a timely manner, of any changes in, or revocation of, the authorization provided to Covered Entity by an individual to use or disclose his or her PHI, to the extent that such changes may affect SE’s use or disclosure of PHI.
                  </li>
                  <li>
                    Encryption. In the event Covered Entity transmits any PHI to SE in connection with the Subscription Agreement that is not transmitted using the Services, Covered Entity shall either (i) encrypt such PHI in accordance with the Secretary of HHS's Guidance to Render Unsecured Protected Health Information Unusable, Unreadable, or Indecipherable to Unauthorized Individuals, available at http//www.hhs.gov/ocr/privacy/hipaa/admmistrative/breachnotificationrule/brguidance.html, as it may be updated from time to time, and as may be made available on any successor or related site designated by HHS, or (ii) verify in writing from SE in advance of transmission that the channel of transmission and recipient system (e.g. fax or e-mail) comply with HIPAA’s security requirements.
                  </li>
                  <li>
                    Restrictions on Disclosures. You will not agree to any retention requests or place any restrictions in any notice of privacy practices that would cause SE to violate any applicable law without first notifying SE in writing and in a timely manner.
                  </li>
                  <li>
                    Compliance with HIPAA. You will not request or cause SE to make a Use or Disclosure of PHI in a manner that does not comply with HIPAA.
                  </li>
                </ol>
              </li>
            </ol>
          </li>
          <li>
            Assignment, etc. You may not, without SE’s prior written consent, assign, delegate or transfer this Agreement, or any of its rights or obligations under this Agreement, to any third party, without SE’s prior written consent, which may be withheld by SE for any reason or no reason, in its sole discretion. SE may assign this Agreement to any third party without the consent of, or notice to, you.
          </li>
          <li>
            Governing Law. This Agreement shall be governed by and construed in accordance with the laws of the Commonwealth of Pennsylvania without reference to its conflict or choice of law rules or principles
          </li>
          <li>
            Jurisdiction and Venue. Any proceeding arising out of or relating to this Agreement, the use of the System or otherwise relating to the tractions contemplated hereby may be brought and maintained only in state or federal courts serving Lancaster County, Pennsylvania, and each of the parties irrevocably submits to the exclusive jurisdiction and venue of each such court in any such proceeding, waives any objection it may now or hereafter have to venue or to convenience of forum, agrees that all claims in respect of such proceeding shall be heard and determined only in any such court and agrees not to bring any such proceeding in any other court.
          </li>
        </ol>
      </p>
    </div>
  )
}

export default TermsAndConditions;