import {
  BENCHMARKS_FETCH_SUCCESS,
  BENCHMARKS_FETCH_FAILURE,
  FETCHING_BENCHMARKS
} from "../_actions/_types.actions";
import {round} from "../../_helpers/demo_national_benchmarks";
// import {nurses, physicians, advanced_practice_providers} from "./results_reducer";

const initialState = {
  average_scores: {},
  frequency_scores: {},
  klas_frequency_scores: {},
  fetchingBenchmarks: false,
  benchmarksFetchError: null
};

export default function benchmarks(state = initialState, action) {
  switch (action.type) {
    case FETCHING_BENCHMARKS:
      return Object.assign({}, state,{fetchingBenchmarks: action.status});
    case BENCHMARKS_FETCH_SUCCESS:
      return Object.assign({}, state, {...calculateAllLicenseTypeBenchmarks(action.data), benchmarksFetchError: null});
    case BENCHMARKS_FETCH_FAILURE:
      return Object.assign({}, state, {benchmarksFetchError: action.error});
    default:
      return state;
  }
}

// eslint-disable-next-line
function combineAverageBenchmarks(benchmarks) {
  if(benchmarks.length === 0){
    return []
  }

  // use first entry as template
  const retValue = benchmarks[0].map(b => ({name: b.name, question_id: b.question_id, short_question: b.short_question, response_count: 0, y: 0}))

  benchmarks.forEach(benchmark => {
    benchmark.forEach((b,i) => {
      retValue[i].response_count += b.response_count
      retValue[i].y += b.response_count * b.y
    })
  })

  retValue.forEach(b => b.y = b.y/b.response_count)

  return retValue
}

// eslint-disable-next-line
function combineIndividualScoreBenchmarks(benchmarks, benchmarkName) {
  if (benchmarks.length === 0) {
    return []
  }
  // console.log(benchmarks);
  // use first entry as template
  const retValue = benchmarks[0].map(b => {
    // console.log(b)
    return {
      name: benchmarkName,
      question_id: b.question_id,
      short_question: b.short_question,
      data: b.data.map(d => {
        // console.log(d)
        return {
          name:d.name,
          response_count:0,
          score:d.score,
          y:0
        }
      })
    }
  });

  const total_responses_per_question = benchmarks[0][0].data.map(d=>0);

  benchmarks.forEach(benchmark => {
    benchmark.forEach((b,i) => {
      b.data.forEach((d,j) => {
        total_responses_per_question[i]+=d.response_count
        retValue[i].data[j].response_count += d.response_count
      })
    })
  })

  retValue.forEach((b,i)=> {
    b.data.forEach((d,j) => {
      // console.log(retValue[i].data[j].response_count,total_responses_per_question[i],(retValue[i].data[j].response_count/total_responses_per_question[i])*100)
      retValue[i].data[j].y = (retValue[i].data[j].response_count/total_responses_per_question[i])*100
    })
  })

  return retValue;
}

// this creates the benchmarks values for "All Physicians","All Nurses", and "All Advanced Practice Providers"
function calculateAllLicenseTypeBenchmarks(data) {
  const {average_scores, frequency_scores, klas_frequency_scores} = data;

  // Skipping over this code (thus making this function redudant)
  // concept of 'All Physician', 'All Nurse', 'All APP' has become too convoluted due to
  // constant additions of new licenses from new clients

  // const nurse_average_scores = {
  //   name: "SE Healthcare Database All Nurses",
  //   data: combineAverageBenchmarks(Object.keys(average_scores)
  //   .filter(k => nurses.includes(k) && average_scores[k].data.length > 0)
  //   .map(k => average_scores[k].data))
  // }
  // const physician_average_scores = {
  //   name: "SE Healthcare Database All Physicians",
  //   data: combineAverageBenchmarks(Object.keys(average_scores)
  //   .filter(k => physicians.includes(k) && average_scores[k].data.length > 0)
  //   .map(k => average_scores[k].data))
  // };
  // const app_average_scores = {
  //   name: "SE Healthcare Database APP",
  //   data: combineAverageBenchmarks(Object.keys(average_scores)
  //   .filter(k => advanced_practice_providers.includes(k) && average_scores[k].data.length > 0)
  //   .map(k => average_scores[k].data))
  // };
  //
  // const nurse_frequency_scores = combineIndividualScoreBenchmarks(Object.keys(frequency_scores)
  // .filter(k => nurses.includes(k) && frequency_scores[k].length > 0)
  // .map(k => frequency_scores[k]), "SE Healthcare Database All Nurses");
  //
  // const physician_frequency_scores = combineIndividualScoreBenchmarks(Object.keys(frequency_scores)
  // .filter(k => physicians.includes(k) && frequency_scores[k].length > 0)
  // .map(k => frequency_scores[k]), "SE Healthcare Database All Physicians");
  //
  // const app_frequency_scores = combineIndividualScoreBenchmarks(Object.keys(frequency_scores)
  // .filter(k => advanced_practice_providers.includes(k) && frequency_scores[k].length > 0)
  // .map(k => frequency_scores[k]), "SE Healthcare Database APP");
  //
  // return {
  //   average_scores: Object.assign({
  //     "All Physicians":physician_average_scores,
  //     "All Nurses":nurse_average_scores,
  //     "All Advanced Practice Providers":app_average_scores
  //   },average_scores),
  //   frequency_scores: Object.assign({
  //     "All Physicians":physician_frequency_scores,
  //     "All Nurses":nurse_frequency_scores,
  //     "All Advanced Practice Providers":app_frequency_scores
  //   },frequency_scores),
  //   klas_frequency_scores: Object.assign({
  //     "All Physicians": klas_frequency_scores["Physician"],
  //     "All Nurses": klas_frequency_scores["Nurse"],
  //     "All Advanced Practice Providers": klas_frequency_scores["Advanced Practice Provider"]
  //   },klas_frequency_scores)
  // }

  return { average_scores,frequency_scores,klas_frequency_scores }
}

/*
SELECTOR FUNCTIONS
These following functions are pretty much selector functions so we could memonized these with https://github.com/reduxjs/reselect
 */

export const getFrequencyScoresBenchmark = (benchmarks, license_filter) => {
  if (benchmarks.frequency_scores && benchmarks.frequency_scores[license_filter]){
    return benchmarks.frequency_scores[license_filter]
  } else if (benchmarks.average_scores && benchmarks.average_scores['All']) {
    return benchmarks.average_scores['All']
  } else {
    return []
  }
}

export const getAverageScoresBenchmark = (benchmarks, license_filter) => {
  if (benchmarks.average_scores && benchmarks.average_scores[license_filter]){
    return benchmarks.average_scores[license_filter]
  } else if (benchmarks.average_scores && benchmarks.average_scores['All']) {
    return benchmarks.average_scores['All']
  } else {
    return {}
  }
}

export const getFrequencyOfBurnoutBenchmark = (frequency_scores, license_filter) => {
  if (frequency_scores && frequency_scores[license_filter] && frequency_scores[license_filter] !== {}){
    return frequency_scores[license_filter].find(b => b.short_question === process.env.REACT_APP_BURNOUT_SHORT_QUESTION);
  } else if (frequency_scores && frequency_scores["All"]) {
    return frequency_scores["All"].find(b => b.short_question === process.env.REACT_APP_BURNOUT_SHORT_QUESTION);
  } else {
    return {}
  }
}

export const getBurnoutRatioBenchmark = (frequency_of_burnout, questions) => {
  // const frequency_of_burnout = getFrequencyOfBurnoutBenchmark(benchmarks, license_filter);

  if (!frequency_of_burnout || (Object.keys(frequency_of_burnout).length === 0 && frequency_of_burnout.constructor === Object)) {
    return {}
  }

  // determine burnout_question of survey so that we can pull choices from it instead of hardcoding choices
  const burnout_question = questions.find(q => q.question.short_question === process.env.REACT_APP_BURNOUT_SHORT_QUESTION)
  if (!(burnout_question && burnout_question.question && burnout_question.question.choices && burnout_question.question.choices.length > 0)){
    return {}
  }

  const preferred_choices = burnout_question.question.choices.filter(c => c.is_preferred).map(c => c.choice);
  const unpreferred_choices = burnout_question.question.choices.filter(c => c.is_not_preferred).map(c => c.choice);

  const presence_of_burnout = frequency_of_burnout.data.filter(d => unpreferred_choices.includes(d.name)).reduce((acc,val) => acc + val.y, 0);
  const no_presence_of_burnout = frequency_of_burnout.data.filter(d => preferred_choices.includes(d.name)).reduce((acc,val) => acc + val.y, 0);

  return {
    name: frequency_of_burnout.name,
    data: [
      {name: 'Presence of Burnout', y: round(presence_of_burnout)},
      {name: 'No Presence of Burnout', y: round(no_presence_of_burnout)}
    ]
  };
}