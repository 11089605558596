import {
  FETCHING_GROUPS,
  GROUPS_FETCH_FAILURE,
  GROUPS_FETCH_SUCCESS,
  SELECT_GROUP,
  RESET_GROUP
} from "../_actions/_types.actions";

let currentGroup = JSON.parse(localStorage.getItem('currentGroup'));
const initialState = {
  groups: [],
  fetchingGroups: false,
  groupsFetchError: null,
  currentGroup
};

export default function groups(state=initialState, action) {
  switch (action.type) {
    case FETCHING_GROUPS:
      return Object.assign({}, state, {fetchingGroups: action.status})
    case GROUPS_FETCH_SUCCESS:
      return Object.assign({}, state, {groups: action.data, groupsFetchError: null})
    case GROUPS_FETCH_FAILURE:
      return Object.assign({}, state, {groupsFetchError: action.error});
    case SELECT_GROUP:
      return Object.assign({}, state, {currentGroup: action.group});
    case RESET_GROUP:
      return Object.assign({}, state, {currentGroup: null});
    default:
      return state;
  }
}


export const groupName = groups => {
  if (groups && groups.currentGroup && groups.currentGroup.name) {
    return groups.currentGroup.name;
  } else {
    return "";
  }
};

export const groupId = groups => {
  if (groups && groups.currentGroup && groups.currentGroup.id) {
    return groups.currentGroup.id;
  } else {
    return 0;
  }
};

export const groupLogoUrl = groups => {
  if (groups && groups.currentGroup && groups.currentGroup.logo_url) {
    return groups.currentGroup.logo_url;
  } else {
    return "";
  }
};

// helper method for determining if a given group, is part of a given system_id
const verifyGroupSystem = (group, system_id) => (
  group &&
  group.group_systems &&
  group.group_systems
  .map( system => system.system_id)
  .includes(system_id)
);

export const isTcmGroup = groups => {
  if (groups && groups.currentGroup) {
    return verifyGroupSystem(groups.currentGroup,parseInt(process.env.REACT_APP_TCM_SYSTEM_ID))
  } else {
    return false;
  }
};
